.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
    transition: opacity 0.5s ease-in-out;
}



.container {
    min-height: 100vh;
    height: fit-content;
    margin: 10px auto auto;
    width: 50vw;
    min-width: 400px;
    /* border: 1px solid red; */
}
.container.fadeIn {
    opacity: 1;
  }
  
  .address, .orders {
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .address:hover, .orders:hover {
    background-color: #f0f0f0;
  }
  
  .productForm, .allOrder {
    transition: max-height 0.5s ease, opacity 0.5s ease;
    max-height: 1000px;
    opacity: 1;
  }
  
  .productForm.collapsed, .allOrder.collapsed {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }
.profileDetails {
    height: 10vw;
    min-height: 150px;
    width: 100%;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    border-bottom: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.logOutBtn {
    height: 35px;
    width: 130px;
    font-size: 15px;
    color: white;
    background-color: black;
    border: none;
    border-radius: 4px;
    transition: all 0.3s ease;
    position: relative;
}

.logOutBtn:hover {
    background-color: #009578;
}

.logOutBtn:active {
    background-color: #009578;
    transform: scale(0.95); 
}

.loggingOut {
    background-color: #009578;
    color: black;
    width: 150px;
    cursor: not-allowed;
    animation: pulse 1.5s infinite; 
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(1);
    }
}

.logOutBtn::after {
    content: '';
    display: inline-block;
    border: 3px solid white;
    border-top: 3px solid black;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 25%;
    left: 40%;
    transform: translate(-50%, -50%);
    animation: spinner 0.8s linear infinite;
    visibility: hidden; 
}

.logOutBtn.loggingOut::after {
    visibility: visible;
}


@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.address,
.orders,
.editProfile {
    height: 4vw;
    min-height: 40px;
    width: 100%;
    font-family: "Poppins", sans-serif;
    border-bottom: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.openAddress {
    height: fit-content;
    width: 100%;
    /* border: 1px solid black; */
}

.productForm {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    /* background-color: #f4f4f4; */
    border-radius: 8px;
}

.productForm h2 {
    font-family: "Poppins", sans-serif;
    font-weight: 550;
    display: block;
    text-align: center;
    margin-bottom: 20px;
}

.formGroup {
    margin-bottom: 15px;
}

.formGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.formGroup input,
.formGroup textarea {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.formGroup textarea {
    resize: vertical;
}

.submitButton {
    width: 100%;
    padding: 10px;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.submitButton:hover {
    background-color: #218838;
}

.errorMessage {
    color: red;
    padding: 2vw;
    font-family: "Poppins", sans-serif;
}

.openOrder {
    height: fit-content;
    width: 100%;
    /* border: 1px solid black */
}

.allOrder {
    height: fit-content;
    width: 100%;
    padding: 0 10px;
    margin-top: 1vw;
    /* border: 1px solid blue; */
    background-color: #ffffff;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
}

.productDiv {
    width: 100%;
    height: auto;
    padding: 10px 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 9px 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
}

.productDiv:hover {
    background-color: #f1f1f1;
}

.productImg {
    width: 15%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    margin-right: 10px;
}

.productDetails {
    flex: 1;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 19px;
}

.productDetails p {
    margin: 0;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    color: #333;
}

.productDetails p:first-child {
    font-weight: 500;
    font-size: 13px;
    color: #000;
}


.productDetails {
    padding: 10px 0;
    gap: 5px;
}
.productDetails p:nth-child(4){
    font-size: 11px;
}
.productDetails p:nth-child(3){
    font-size: 11px;
    font-weight: 500;
}
.productDetails p:nth-child(2){
    font-size: 11px;
    font-weight: 500;
}
.price, .quantity, .deliveryInfo {
    font-size: 14px;
    color: #666;
}

.deliveryInfo {
    font-style: italic;
    color: #888;
}
.p {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}

.addressDiv {
    height: fit-content;
    /* border: 1px solid black; */
    margin: 0 auto;
}
.errorText {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }
.sepAddressDiv,
.fullAddress {
    width: 70%;
    /* border: 1px solid red; */
    padding: 5px;
    margin: 0 auto;
    overflow: auto;
}

.sepAddressDiv {
    height: 190px;
    font-family: "Poppins", sans-serif;
    font-size: 15px;

}

.fullAddress {
    height: 100px;
    font-family: "Poppins", sans-serif;

}

.addressh3 {
    display: inline;
    font-size: 18px;
    font-family: "Poppins", sans-serif;

}

.addressp {
    font-size: 14px;
    display: inline;
    font-family: "Poppins", sans-serif;
}

.sepAddress {
    /* border: 1px solid green; */
    display: flex;
    justify-content: space-between;
}

.sepAddress h3 p {
    display: inline;
}
@media screen and (max-width: 768px) {
    .container {
        width: 80vw;
    }
    .productImg {
        width: 15%;
    }
    .productDetails p:first-child{
        font-size: 13px;
    }
    .productDetails p:nth-child(4){
        font-size: 11px;
    }
    .productDetails p:nth-child(3){
        font-size: 11px;
        font-weight: 500;
    }
    .productDetails p:nth-child(2){
        font-size: 11px;
        font-weight: 500;
    }
    .price, .quantity, .deliveryInfo {
        font-size: 14px;
        color: #666;
    }
    
    
}

@media (max-width:425px) {
    .container {
        min-width: 95vw;
    }

    .productDiv {
        flex-direction: row;
        align-items: center;
        padding: 5px 4px;
    }
    .productDetails{
        gap: 5px;
    }
    .profileDetails h3{
        font-size: 15px;
    }

    .productImg {
        width: 30%;
        height: auto;
    }
    .productDetails p:first-child{
        font-size: 13px;
    }

    .productDetails {
        padding: 10px 0;
    }
    .productDetails p:nth-child(4){
        font-size: 11px;
    }

    .addressDiv {
        width: 100%;
    }

    .sepAddressDiv,
    .fullAddress {
        width: 100%;
    }

    .addressp {
        font-size: 13px;
    }

    .addressh3 {
        font-size: 15px;

    }

    .sepAddressDiv {
        font-size: 13px;

    }

}