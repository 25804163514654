.skeletonContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px;
    justify-content: center;
    margin-top: 120px;
  }
  
   .skeletonCard {
    width: calc(25% - 16px);  
    max-width: 240px;
    background-color: #f3f3f3;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
  }
  
   .skeletonImage {
    width: 100%;
    height: 150px;
    background-color: #e0e0e0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }
  
  .skeletonImage::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 200%;
    background: linear-gradient(90deg, #e0e0e0, #f5f5f5, #e0e0e0);
    animation: shimmer 1.5s infinite;
  }
  
   .skeletonDetails {
    margin-top: 12px;
  }
  
  .skeletonTitle,
  .skeletonPrice,
  .skeletonBadge {
    height: 16px;
    background-color: #e0e0e0;
    margin-bottom: 8px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }
  
  .skeletonTitle {
    width: 70%;
  }
  
  .skeletonPrice {
    width: 50%;
  }
  
  .skeletonBadge {
    width: 60%;
  }
  
   @keyframes shimmer {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
  
   @media (max-width: 768px) {
    .skeletonContainer{
        margin-top: 80px;
    }
    .skeletonCard {
      width: calc(33.3333% - 16px);  
    }

  }
  
  @media (max-width: 480px) {
    .skeletonCard {
      width: calc(50% - 16px); 
    }
  }
 

.newProductHeading {
    text-align: center;
    font-size: 32px;
    margin-bottom: 5px;
    animation: slideIn 1s ease-out;
}

.newProductSubheading {
    text-align: center;
    font-size: 20px;
    color: #ab9090;
    animation: fadeIn 1.5s ease-in-out;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    animation: fadeIn 1s ease-in-out;
}

.maindiv {
    background-color: #ffffff;
    border: 2px solid #e0e0e0;
    border-radius: 15px;
    margin: 5px;
    padding: 5px;
    width: 270px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: popUp 1s ease-in-out;
}

.maindiv:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.imageContainer {
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 15px;
    transition: transform 0.5s ease;
}

.imageContainer:hover {
    transform: scale(1.05);
}

.image {
    width: 100%;
    height: auto;
    transition: transform 0.5s ease;
}

.details {
    margin-top: 10px;
}

.title {
    font-size: 13px;
    color: #333;
    margin-bottom: 5px;
}

.price {
    font-size: 17px;
    font-weight: bold;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes popUp {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .container {
        flex-direction: row; 
        flex-wrap: wrap; 
        justify-content: space-between; 
    }

    .newProductSubheading {
        font-size: 16px;
    }

    .maindiv {
        width: calc(50% - 4px);  
        margin: 10px 0;  
        cursor: none;
    }

    .maindiv:hover {
        transform: none;
        box-shadow: none;
    }

    .imageContainer:hover {
        transform: none;
    }

    .imageContainer {
        overflow: hidden;
        transition:none;
    }


    .details h3{
        font-size: 9px;
        display: block;
    }
    .price {
        font-size: 14px;
        display: block;

    }
}
