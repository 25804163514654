.container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem;
    background-color: #ba9976;
    color: #f0f0f0;
  }
  
  .textSection {
    flex: 1;
    min-width: 300px;
    max-width: 600px;
    margin-right: 1rem;
    animation: fadeIn 1s ease-in-out;
  }
  .title {
    font-size: 2rem;
    font-weight: 400;
    font-family: Tiemann, serif;
    margin-bottom: 1rem;
    color: #2c3e50;
    position: relative;
  }
  
  .title::after {
    content: "";
    display: block;
    width: 50px;
    height: 3px;
    background: #f9a602;
    margin-top: 5px;
    margin-left: 0.9rem;
    animation: slideIn 0.8s ease-out;
  }
  @keyframes slideIn {
    from {
      width: 0;
    }
    to {
      width: 50px;
    }
  }
  .subtitle {
    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
  
  .options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .option {
    border-bottom: 1px solid ;
    padding: 1rem ;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .option:hover {
    background-color: #f0f0f0;
    color: #ba9976;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .optionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .icon {
    font-size: 1.5rem;
    color: #f9a602;
    margin-right: 0.5rem;
  }
  
  .arrow, .arrowExpanded {
    font-size: 1.2rem;
    transition: transform 0.3s ease;
  }
  
  .arrowExpanded {
    transform: rotate(90deg);
  }
  
  .optionText {
    font-size: 1rem;
  color: #34495e;
  margin-top: 0.5rem;
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  }
  
  .expandedText {
    max-height: 100px;  
    opacity: 1;
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: #f6f6f6;
  }
  
  .option:hover .optionText {
    color: #ba9976;
    max-height: 100px;
    opacity: 1;
  }
  .imageSection {
    flex: 1;
    max-width: 50%;
    display: flex;
    justify-content: center;
  }
  
  .imageWrapper {
    position: relative;
    width: 80%;
    max-width: 400px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  }
  
  @keyframes floatAndScale {
  0%, 100% {
    transform: scale(1) translateY(0);
  }
  50% {
    transform: scale(1.05) translateY(-5px);
  }
}

 .image {
  width: 100%;
  border-radius: 50%;
  animation: floatAndScale 5s ease-in-out infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

  .overlayIcon {
    position: absolute;
    top: 15%;
    right: 15%;
    font-size: 2.5rem;
    color: #58b4ae;
    opacity: 0.8;
    animation: spin 4s linear infinite;
  }
  
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
   @media (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 1rem;
    }
  
    .textSection{
        text-align: center;
    }
    .textSection, .imageSection {
      max-width: 100%;
      padding: 1rem 0;
    }
    .options {
        gap: 0.5rem;
      }
    
  
    .title {
      font-size: 2rem;
    }
    .overlayIcon{
        font-size: 2rem;
    }
    .option{
        cursor: none;
    }
  
    .optionText {
      font-size: 0.85rem;
    }
    .title::after{
        margin-left: 13.3rem;
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 1.8rem;
    }
  
    .subtitle {
      font-size: 1rem;
    }
  
    .optionHeader h3 {
      font-size: 1rem;
    }
  
    .optionText {
      font-size: 0.9rem;
    }
  
    .icon {
      font-size: 1.2rem;
    }
  
    .arrow, .arrowExpanded {
      font-size: 1rem;
    }
    .title::after{
        margin-left: 1.3rem;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  