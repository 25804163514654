 .skeletonContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 2vw;
    justify-content: center;
    padding: 1vw;
    animation: fadeInUp 1.2s ease-out;
}

.skeletonItem {
    width: 18vw;
    height: 200px;
    background-color: #e0e0e0;
    border-radius: 12px;
    animation: skeletonLoading 1.5s ease-in-out infinite;
}

@keyframes skeletonLoading {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #d3d3d3;
    }
    100% {
        background-color: #e0e0e0;
    }
}

 .maindiv {
    width: 18vw;
    cursor: pointer;
    transition: transform 0.5s ease, box-shadow 0.5s ease;
    animation: floatUp 2s ease-out;
}

.maindiv:hover {
    transform: scale(1.1) rotate(2deg);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

 
.outerDiv {
    height: fit-content;
    min-height: 100vh;
    width: 80vw;
    margin: 0 auto;
    padding-top: 10px;
    animation: fadeIn 1.5s ease-in-out; 
}

.formDiv {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    animation: slideDown 1s ease-out; 
}

.input, .activeInput {
    width: 100%;
    height: 40px;
    padding: 10px;
    font-size: 14px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border: 1px solid black;
    border-radius: 5px;
    margin: 15px 0;
    transition: all 0.4s ease;
    outline: none; 
}

.activeInput {
    border: 2px solid #00a86b;
    box-shadow: 0px 0px 12px rgba(0, 168, 107, 0.5);
    background-color: #e6fff2;
    border-radius: 10px;
    transform: scale(1.05);
    animation: glowEffect 1.5s ease-in-out infinite alternate; 
}

.input:hover {
    border-color: #ffae42;
    background-color: #fff8e1;
    transform: scale(1.02); 
}

.date {
    font-family: Futura, sans-serif;
    letter-spacing: 1px;
    padding-left: 7px;
    font-size: 42px;
    font-weight: 500;
    color: #EDA841;
    animation: popIn 1s ease-out;
}

.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2vw;
    padding: 1vw;
    animation: fadeInUp 1.2s ease-out; 
}

.maindiv {
    width: 18vw;
    cursor: pointer;
    transition: transform 0.5s ease, box-shadow 0.5s ease; 
    animation: floatUp 2s ease-out;
}

.maindiv:hover {
    transform: scale(1.1) rotate(2deg); 
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

.image {
    height: 80%;
    animation: bounceIn 1s ease-out; 
}

.image img {
    width: 100%;
    border-radius: 12px;
    transition: transform 0.5s ease;
}

.image img:hover {
    transform: scale(1.1);
}

.details {
    text-align: center;
    padding: 5px;
    font-size: 13px;
    font-family: Futura, sans-serif;
    letter-spacing: 1px;
    animation: fadeIn 1s ease;
}


@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes slideDown {
    0% { transform: translateY(-100px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
}

@keyframes floatUp {
    0% { transform: translateY(50px); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
}

@keyframes glowEffect {
    0% { box-shadow: 0 0 8px rgba(0, 168, 107, 0.3); }
    100% { box-shadow: 0 0 16px rgba(0, 168, 107, 0.7); }
}

@keyframes popIn {
    0% { transform: scale(0.8); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
}

@keyframes fadeInUp {
    0% { opacity: 0; transform: translateY(50px); }
    100% { opacity: 1; transform: translateY(0); }
}

@keyframes bounceIn {
    0% { transform: scale(0.8); opacity: 0; }
    50% { transform: scale(1.1); opacity: 0.8; }
    100% { transform: scale(1); opacity: 1; }
}

@media (max-width: 768px) {
    .outerDiv {
        width: 100vw;
    }

    .formDiv {
        width: 90%;
    }

    .input, .activeInput {
        height: 35px;
        margin: 5px 0;
    }

    .date {
        font-size: 28px;
    }

    .maindiv {
        width: 40vw;
        padding: 10px;
        cursor: none;
    }

    .details {
        font-size: 10px;
    }
}
