.paymentOptionsPage {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}
  
.paymentHeader {
    text-align: center;
    margin-bottom: 40px;
}
  
.paymentHeader h1 {
    font-family: 'Arial', sans-serif;
    font-size: 2rem;
    margin-bottom: 10px;
}
  
.paymentCard h2 {
    font-family: 'Tiemann', serif;
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #009578;
}
  
.paymentHeader h4 {
    font-size: 1.2em;
    margin-top: 0px;
    margin-bottom: 10px;
}
  
.paymentHeader .icon {
    font-size: 3em;
    margin-top: 10px;
    margin-bottom: 0px;
    color: #3395FF;
}
  
.paymentHeader p {
    font-family: 'Futura', sans-serif;
    font-size: 1.2rem;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
}
  
.paymentMethods {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}
  
.paymentCard {
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    width: calc(33.333% - 40px); 
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
  
.paymentCard:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}
  
.paymentCard p {
    font-family: 'Arial', sans-serif;
    color: #333;
}
  
.paymentCard .icon {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #BA9976;
    animation: bounceInIcon 1s ease-in-out infinite;
}
  
@keyframes bounceInIcon {
    0% {
        opacity: 0;
        transform: scale(0.3);
    }
    50% {
        opacity: 1;
        transform: scale(1.1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
  
@media (max-width: 768px) {
    .paymentCard {
        width: calc(50% - 20px); 
    }
}
  
@media (max-width: 480px) {
    .paymentCard {
        width: 100%; 
    }
}
