.authContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 87vh;
    padding: 1rem;
}

.toastContent {
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif;
}

.toastContent .icon {
    color: #28a745; 
    margin-right: 10px;
    font-size: 20px;
}

.successToast {
    background-color: #fff; 
    border-left: 5px solid #28a745;
    color: #333;
    padding: 10px;
    border-radius: 10px;
}
.error {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    color: red;
    margin-bottom: 20px;
}

.authForm {
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 1rem;
    border-radius: 18px;
    height: fit-content;
    width: 100%;
    max-width: 400px;
    animation: fadeIn 1s ease-in-out;
}

.heading {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 30px;
    color: #009578;
    padding: 7px 0;
}

.label {
    font-family: "Poppins", sans-serif;
    display: block;
    font-size: 15px;
    padding: 5px 0;
}

.inputField {
    font-family: "Poppins", sans-serif;
    display: block;
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    
}

.passwordWrapper {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 1rem;
}

.togglePassword {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: #009578;
}

.submit {
    width: 100%;
    background-color: #009578;
    color: white;
    font-family: "Poppins", sans-serif;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #009578;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 10px;
    position: relative;
    overflow: hidden;
}

.submit:hover {
    background-color: #007a6e;
    border: 1px solid #007a6e;
}

.submit:active {
    transform: scale(0.95);
}

.submit.disabled {
    background-color: #009578; 
    cursor: not-allowed;
}

.submit.disabled .spinner {
    display: block;
}

.submit .spinner {
    display: none;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 3px solid white;
    border-top: 3px solid #000;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: spinner 0.8s linear infinite;
}
.res{
    color: #000;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    margin-top: 10px;
}

@keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.instruction {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    margin-bottom: 10px;
}

.instruction span {
    color: #009578;
    cursor: pointer;
}

.instruction span:hover {
    text-decoration: underline;
}

.errorDiv {
    width: 100%;
    text-align: center;
}

.error {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    color: red;
    margin-bottom: 20px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .authForm {
        width: 98%;
    }

    .authContainer {
        height: 90vh;
    }
}
