 .skeletonContainer {
  display: flex;
  width: 90%; 
  max-width: 900px;  
  margin: 20px auto;  
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

 .skeletonHeader,
.skeletonFooter {
  height: 20px;
  background-color: #e0e0e0;
  margin-bottom: 12px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.skeletonHeader::before,
.skeletonFooter::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;  
  height: 100%;
  width: 200%; 
  background: linear-gradient(90deg, #e0e0e0, #f4f4f4, #e0e0e0);
  animation: shimmer 1.5s infinite;
}

 .skeletonBody {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;  
}

 .skeletonImage {
  width: 80px;
  height: 80px;
  background-color: #e0e0e0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.skeletonImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%; 
  height: 100%;
  width: 200%;  
  background: linear-gradient(90deg, #e0e0e0, #f4f4f4, #e0e0e0);
  animation: shimmer 1.5s infinite;
}

 .skeletonDetails {
  flex: 1;
  min-width: 150px;  
}

.skeletonLine {
  height: 12px;
  background-color: #e0e0e0;
  margin: 8px 0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.skeletonLine::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;  
  height: 100%;
  width: 200%;  
  background: linear-gradient(90deg, #e0e0e0, #f4f4f4, #e0e0e0);
  animation: shimmer 1.5s infinite;
}

.skeletonPrice {
  height: 16px;
  background-color: #e0e0e0;
  width: 60%;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.skeletonPrice::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;  
  height: 100%;
  width: 200%;  
  background: linear-gradient(90deg, #e0e0e0, #f4f4f4, #e0e0e0);
  animation: shimmer 1.5s infinite;
}

 @keyframes shimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

 @media (max-width: 768px) {
  .skeletonContainer {
    width: 95%;  
    margin: 16px auto;  
  }

  .skeletonBody {
    gap: 12px;  
    flex-direction: column;  
  }

  .skeletonImage {
    width: 100%;  
    height: 150px;  
  }

  .skeletonDetails {
    width: 100%;  
  }
}

@media (max-width: 480px) {
  .skeletonContainer {
    padding: 12px;  
  }

  .skeletonImage {
    height: 100px;  
  }

  .skeletonPrice {
    width: 80%;  
  }
}

.noOrders {
  display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px;
    /* max-width: 400px; */
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.noOrders p {
  color: #666;
  margin-top: 10px;
}
.noOrdersTitle {
  font-size: 26px;
  color: #009578;
  margin-bottom: 10px;
}

.message {
  font-size: 18px;
  color: #555;
  margin-bottom: 20px;
}

.noOrdersBorder {
  height: 3px;
  background-color: #BA9976;
  margin-top: 20px;
}
.icon {
  font-size: 100px;
  margin-bottom: 10px;
  animation: bounce 1s infinite alternate;
}

@keyframes bounce {
  from {
      transform: translateY(0);
  }
  to {
      transform: translateY(-10px);
  }
}
.noOrders svg {
  font-size: 100px;
  color: #BA9976;
}

.noOrdersText {
  font-size: 18px;
  margin-top: 10px;
  color: #666;
}
 .container { 
    width: 100%; 
    max-width: 1200px; 
    margin: 0 auto; 
    padding: 20px; 
  } 
  
   .header { 
    font-size: 1.7rem; 
    color: #333; 
  } 

  
   .orderList { 
    display: flex; 
    flex-direction: column; 
    gap: 10px; 
  } 
  
  
  .orderItem { 
    width: 100%;
    height: auto;
    padding: 10px 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9;
    cursor: pointer;
  } 
  
  .image { 
    width: 120px; 
    height: 120px; 
    object-fit: cover; 
    border-radius: 8px; 
    margin-right: 20px; 
  } 
  
 
  .orderDetails { 
    flex: 1;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 19px;
  } 
  
  .productTitle { 
    padding-top: 10px;
    font-weight: bold; 
    margin: 0 0 10px; 
  } 
  
  
  .row { 
    display: flex; 
    justify-content: space-between; 
    align-items: flex-end;
    margin-top: 10px; 
    flex-wrap: wrap;
  } 
  .row1{
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    margin-top: 10px; 
    flex-wrap: wrap;
  }
  
  .price { 
    font-size: 1.1rem; 
    font-weight: bold; 
    margin-top: 10px; 
  } 
  
  
  .deliveryStatus { 
    color: green; 
    font-size: 0.9rem; 
    margin-top: 5px; 
    animation: fadeIn 1s ease-in-out;
  } 
  .deliveryStatus span { 
    font-weight: bold; 
  }
  
  .estimatedDate { 
    color: #ff9800; 
    font-size: 0.9rem; 
    margin-top: 5px; 
  } 
  
 
  .reviewButton { 
    display: flex; 
    align-items: center; 
    background-color: #BA9976; 
    color: white; 
    padding: 10px 15px; 
    border: none; 
    border-radius: 4px; 
    cursor: pointer; 
    margin-top: 10px; 
    transition: background-color 0.3s ease; 
    justify-content: center; 
  } 
  
  .reviewButton:hover { 
    background-color: #8a6d4f; 
  } 

  .reviewButton:active { 
    background-color: #5a3e2a; 
  }
  
  .icon1 { 
    margin-right: 5px; 
    animation: pulse 2s infinite; 
    font-size: 1rem;
  } 

  .comingSoon { 
    color: #eaf0f0; 
    font-weight: bold; 
    white-space:unset ; 
    font-size: 0.8rem;
    animation: pulse 2s infinite; 
  } 
  
  @keyframes pulse { 
    0% { 
      transform: scale(1); 
    } 
    50% { 
      transform: scale(1.05); 
    } 
    100% { 
      transform: scale(1); 
    } 
  }
  
  /* Link styles */
  a { 
    color: #e6ebf0; 
    text-decoration: none; 
  } 
  
  a:hover { 
    color: #c5cbd1; 
    text-decoration: underline; 
  } 
  
  a:active { 
    color: #004085; 
  } 

  .ratingStars {
    display: flex;
    gap: 5px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.ratingStars:hover {
    transform: scale(1.1);
}
 
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
.error {
    font-size: 2rem;
    color: #333;
    padding-bottom: 20px;
}
.orderHeader {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Spacing between Order ID and Order Date */
  padding: 10px 15px;
  background-color: #f4f4f4; /* Light background */
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Spacing below the header */
}

/* Styling for Order ID */
.orderHeader h3 {
  font-size: 1.4rem;
  color: #333;
  font-weight: bold;
  margin: 0;
}

.orderId {
  color: #009578; /* A highlight color for the order ID */
  font-weight: normal;
}

/* Styling for Order Date */
.orderDate {
  font-size: 1rem;
  color: #555;
  margin: 0;
}


  .orderGroup {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
  .orderGroup:hover {
    transform: scale(1.02);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.25);
}
.orderGroup:active {
    transform: scale(1.02);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.25);
}
  .orderGroup h3{
    font-size: 1.2rem;
    color: #827777;
    margin-bottom: 0px;
  }




  @media (max-width: 860px) { 
    .container { 
      padding: 10px; 
    }
    .row1{
      display: flex; 
      justify-content: space-around;
    }
    .header {
      font-size: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .image{
    max-width: 100px;
  }
    .reviewButton{
        width: 70%;

    }
    .error {
        font-size: 1.5rem;
     }
    
  }

  
  @media (max-width: 768px) { 

    .noOrders svg {
      font-size: 90px;
    }
  
    .noOrdersText {
      font-size: 16px;
    } .noOrdersTitle {
      font-size: 22px;
  }

  .message {
      font-size: 15px;
  }
    .orderItem {
      flex-direction: row;
      align-items: center;
  }
  .orderDetails {
      padding-left: 10px;
      text-align: left;
  }
  .error{
    font-size: 1.2rem;
  }
  .image {
      max-width: 80px;
      height: auto;
  }
  .productTitle, .price {
      font-size: 1rem;
  }
  .reviewButton {
      font-size: 0.9rem;
      width: 90%;
  }
  .orderGroup h3{
    font-size: 0.8rem;
  }
}

    @media (max-width: 480px) { 
      
      .noOrders svg {
        font-size: 75px;
      }
    
      .noOrdersText {
        font-size: 12px;
      }
      .noOrdersTitle {
        font-size: 18px;
    }

    .message {
        font-size: 14px;
    }
      .orderList {
        grid-template-columns: 1fr;
    }
    .image {
        max-width: 70px;
        height: auto;
    }
    .productTitle, .price {
        font-size: 0.9rem;
    }
    .reviewButton {
        font-size: 0.8rem;
        width: 100%;
    }
    .error{
      font-size: 1rem;
    }
    .orderGroup h3{
      font-size: 0.7rem;
    }
  }

  @media (max-width: 600px) { 
    .image {
      height: 100px;
  }
  .productTitle {
      font-size: 1rem;
  }
  .orderItem{
    cursor: none;
  }
  .estimatedDate{
    font-size: 0.8rem;
    color: #ff9800;
  }
  .row p{
      font-size: 0.75rem;
      color: #827777;
  }
  .error{
    font-size: 1rem;
  }
  .price {
      font-size: 1rem;
      color: #333;
  }
  .reviewButton {
      padding: 8px 12px;
  }
  }