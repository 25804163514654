.disclaimerContainer {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 8px;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-in-out;
}



.disclaimerSection {
    margin: 20px 0;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.disclaimerSection h2 {
    color: #009578;
    margin-bottom: 15px;
    font-size: 1.5rem;
}

.disclaimerSection p {
    color: #555;
    line-height: 1.6;
    font-size: 1rem;
}

@media (max-width: 768px) {
    h1 {
        font-size: 2rem;
    }

    .disclaimerSection {
        padding: 15px;
    }

    .disclaimerSection h2 {
        font-size: 1.2rem;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
