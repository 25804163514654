.swiperContainer {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 20px 10px;
    text-align: center;
  }
  
  .heading {
    font-size: 2.5rem;
    font-weight: 400;
    color: #ba9976;
    margin-bottom: 1rem;
    font-family: Tiemann, serif;
    animation: fadeIn 1s ease-in-out;
  }
  
  .swiper {
    width: 100%;
    overflow: hidden;
    margin-top: 10px;
  }
  
  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card {
    width: 80%;
    max-width: 300px;
    height: 500px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .video {
    width: 100%;
    height: 100%;
    border: none;
    object-fit: cover;
  }
  link {
    text-decoration: none;
  }
  
  .shopButton {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ba9976;
    color: white;
    padding: 12px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none; 
    transition: background-color 0.3s ease;
  }
  
  .shopButton:hover {
    background-color: #917454;
  }
 
  
  @media (max-width: 768px) {
     
     .shopButton {
      font-size: 0.9rem;
      padding: 10px 18px;
    }
  }
  
  @media (max-width: 480px) {
    .shopButton {
      font-size: 0.8rem;
      padding: 8px 15px;
    }
  }
  @media (max-width:375) {
    .shopButton {
      font-size: 0.8rem;
      padding: 8px 12px;
    }
    
  }
  