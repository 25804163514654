.newsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;  
}


.main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.newsCard {
    width: 300px;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    transition: transform 0.3s ease;  
    background-color: #8BA09E;
}

.newsCard:hover {
    transform: translateY(-5px);  
}

.newsCard img {
    width: 100%;
    height: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.newsContent {
    padding: 10px;
}

.button {
    background-color: #044A65;
    color: #fff;
    margin: 7px 0px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;  
    width: 55%; 
}

@media (max-width: 768px) {
    .newsCard {
        width: calc(50% - 20px);  
    }
}

@media (max-width: 480px) {
    .newsCard {
        width: calc(100% - 20px);  
    }
}
