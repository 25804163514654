* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.heroContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #ffffff;
}

.sliderContainer {
  width: 100%;
  height: 100%;
  position: absolute;
}

.mySwiper {
  width: 100%;
  height: 100%;
}

.swiperSlide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.slideWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  animation: fadeInUp 1.5s ease-in-out;
}

.slideImage {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  animation: scaleAnimation 10s infinite;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease;
}

.textSection {
  position: absolute;
  bottom: 10%;
  text-align: center;
  color: #fff;
  animation: fadeIn 2s ease-in-out;
}

.title {
  font-family: Tiemann, serif;
  font-size: 3rem;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  color:aliceblue;
}

.subtitle {
  font-family: Futura, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 0.5rem;
  color: #fff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

@keyframes scaleAnimation {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.9;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


 

@media (max-width: 1200px) {
  .heroContainer {
    height: 90vh;
  }

  .slideImage {
    height: 90vh;
  }

  .title {
    font-size: 3rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }
}

@media (max-width: 1024px){
  .heroContainer {
    height: 80vh;
  }

  .slideImage {
    height: 80vh;
  }

  .title {
    font-size: 2.8rem;
  }

  .subtitle {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .heroContainer {
    height: 70vh;
  }

  .slideImage {
    height: 70vh;
  }

  .title {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .heroContainer {
    height: 35vh;
  }

  .slideImage {
    height: 35vh;
  }

  .title {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 0.9rem;
  }
}
