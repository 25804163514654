 .skeletonContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px;
    justify-content: center;
    margin-top: 120px;
  }
  
   .skeletonCard {
    width: calc(25% - 16px);  
    max-width: 240px;
    background-color: #f3f3f3;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
  }
  
   .skeletonImage {
    width: 100%;
    height: 150px;
    background-color: #e0e0e0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }
  
  .skeletonImage::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 200%;
    background: linear-gradient(90deg, #e0e0e0, #f5f5f5, #e0e0e0);
    animation: shimmer 1.5s infinite;
  }
  
   .skeletonDetails {
    margin-top: 12px;
  }
  
  .skeletonTitle,
  .skeletonPrice,
  .skeletonBadge {
    height: 16px;
    background-color: #e0e0e0;
    margin-bottom: 8px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }
  
  .skeletonTitle {
    width: 70%;
  }
  
  .skeletonPrice {
    width: 50%;
  }
  
  .skeletonBadge {
    width: 60%;
  }
  
   @keyframes shimmer {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
  
   @media (max-width: 768px) {
    .skeletonContainer{
        margin-top: 80px;
    }
    .skeletonCard {
      width: calc(33.3333% - 16px);  
    }

  }
  
  @media (max-width: 480px) {
    .skeletonCard {
      width: calc(50% - 16px); 
    }
  }
  
 

.hotSellsheading {
    font-family: 'Signika', sans-serif;
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
    animation: fadeInDown 0.8s ease-in-out;
}

.hotSellsSubheading {
    text-align: center;
    font-size: 20px;
    margin-bottom: 0px;
    color: #ab9090;
    animation: fadeInDown 1s ease-in-out;
}
.outOfStockBadge {
    position: absolute;
    right:0px;
    background-color: red;
    color: white;
    padding: 3px 6px;
    border-radius: 5px;
    font-size: 12px;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    animation: fadeIn 1.2s ease-in-out;
}

.maindiv {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin: 5px;
    width: 250px;
    text-align: center;
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.maindiv:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.imageContainer {
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 10px;
    transition: transform 0.3s ease;
}

.imageContainer:hover {
    transform: scale(1.05);
}

.image {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
}

.details {
    animation: fadeInUp 0.6s ease-in-out;
}

.title {
    font-size: 13px;
    color: #333;
    animation: fadeInUp 0.7s ease-in-out;
}
.preBooking {
    margin-top: 5px;
}
.preBooking span {
    color: #007a63;
    font-weight: bold;
}


.price {
    font-size: 17px;
    font-weight: bold;
    animation: fadeInUp 0.8s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .container {
        flex-direction: row; 
        flex-wrap: wrap; 
        justify-content: space-between; 
    }
    .hotSellsSubheading {
        font-size: 15px;
    }
    .outOfStockBadge{
        font-size: 9px;
        padding: 2px 4px;
    }
    .preBooking span{
        font-size: 12px;
        font: small-caption;
        font-weight: bold;
    }

    .maindiv {
        width: calc(50% - 4px);  
        margin: 10px 0;  
        cursor: none;
    }
    
    .maindiv:hover{
        transform: none;
        box-shadow: none;
    }
    .imageContainer:hover {
        transform: none;
    }
    .imageContainer {
        overflow: hidden;
        transition:none;
    }


    .details h3{
        font-size: 9px;
     }
    .price {
        font-size: 14px;
 
    }
}
