.aboutPage {
    padding: 20px;
    animation: fadeInPage 1s ease-in-out;
    user-select: none;
  }
  
  .aboutContent {
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
    animation: fadeIn 1s ease-in-out;
  }
  
  .aboutContent h1 {
    font-family: 'Tiemann', serif;
    font-size: 2rem;
    margin-bottom: 20px;
    animation: fadeIn 1s ease-in-out;
  }
  
  .aboutContent h3 {
    font-family: 'Tiemann', serif;
    font-size: 1.5rem;
    margin: 15px 0;
    color: #cc6d08;
  }
  
  .aboutContent p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
    color: #333;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInPage {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @media screen and (max-width: 768px) {
    .aboutContent {
      padding: 0 20px;
    }
  
    .aboutContent h1 {
      font-size: 1.8rem;
    }
  
    .aboutContent h3 {
      font-size: 1.3rem;
    }
  
    .aboutContent p {
      font-size: 0.95rem;
    }
  }
  