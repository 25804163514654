.skeletonContainer {
    padding: 20px;
}

.skeletonGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2vw;
    animation: fadeIn 0.8s ease-in-out;
}

.skeletonCard {
    width: 15vw;
    height: 250px;
    border-radius: 10px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: shimmer 1.5s infinite ease-in-out, slideInLeft 0.5s ease-out;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.skeletonCard:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.skeletonImage {
    width: 100%;
    height: 60%;
    background: linear-gradient(90deg, #d0d0d0 25%, #c8c8c8 50%, #d0d0d0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite ease-in-out;
}

.skeletonTitle {
    width: 80%;
    height: 15px;
    margin: 10px auto;
    background: linear-gradient(90deg, #c0c0c0 25%, #b8b8b8 50%, #c0c0c0 75%);
    background-size: 200% 100%;
    border-radius: 4px;
    animation: shimmer 1.5s infinite ease-in-out;
}

.skeletonPrice {
    width: 50%;
    height: 15px;
    margin: 5px auto;
    background: linear-gradient(90deg, #c0c0c0 25%, #b8b8b8 50%, #c0c0c0 75%);
    background-size: 200% 100%;
    border-radius: 4px;
    animation: shimmer 1.5s infinite ease-in-out;
}

.skeletonReadMoreButton {
    width: 200px;
    height: 40px;
    margin: 30px auto;
    background: linear-gradient(90deg, #e0e0e0 25%, #d6d6d6 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    border-radius: 5px;
    animation: shimmer 1.5s infinite ease-in-out, pulse 2s infinite alternate;
    transition: transform 0.3s ease;
}

.skeletonReadMoreButton:hover {
    transform: scale(1.1);
    background: linear-gradient(90deg, #d6d6d6 25%, #e0e0e0 50%, #d6d6d6 75%);
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f5f5f5;
    }
    100% {
        background-color: #e0e0e0;
    }
}

@keyframes slideInLeft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@media (max-width: 768px) {
    .skeletonGrid {
        gap: 5vw;
    }
    .skeletonCard {
        width: 40vw;
    }
    .skeletonReadMoreButton {
        width: 150px;
    }
}


.icon {
    display: block;
    margin: auto;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2vw;
    padding: 1vw;
    cursor: pointer;
    margin-bottom: 1.5rem;
}

.allSariHeading {
    font-family: signika, sans-serif;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 20px;
}



.maindiv {
    width: 15vw;
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    opacity: 0;   
    transform: translateX(-100%);   
}

.maindiv.visible {
    animation: slideInLeft 0.8s ease-in-out forwards;
 }

.maindiv:hover {
    transform: scale(1.08);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 150, 120, 0.3);
 }

 
.image {
    height: auto;
}

.image img {
    width: 100%;
    height: auto;
}
.outOfStockBadge {
    position: absolute;
    right:0px;
    background-color: red;
    color: white;
    padding: 3px 6px;
    border-radius: 5px;
    font-size: 12px;
}

 
.details p {
    font-weight: bold;
    font-size: 15px;
}

.details {
    text-align: center;
    padding: 5px 5px;
    font-size: 14px;
    font-family: 'Futura', sans-serif;
    letter-spacing: 1px;
    color: #333;
     border-radius: 0 0 10px 10px;
    transition: background-color 0.3s ease;
}
.preBooking {
    margin-top: 5px;
}
.preBooking span {
    color: #007a63;
    font-weight: bold;
}
.addToCartButton {
    background-color: #009578;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.addToCartButton:hover {
    background-color: #007a63;
}




@media (max-width: 768px) {
    .allSariHeading{
        font-size: 24px;
        font-family:'Courier New', Courier, monospace;
        font-weight: bold;

    }
    .maindiv {
        width: 40vw;
         transition: none;
    }

    .maindiv:hover {
        transform: none;
        box-shadow: none;
    }
    .outOfStockBadge{
        font-size: 9px;
        padding: 2px 4px;
    }
    .details {
        font-size: 12px;
    }

    .container {
        cursor: none;
    }
    .preBooking span{
        font-size: 12px;
        font: small-caption;
        font-weight: bold;
    }
}

 
.homeImg {
    height: 90vh;
    width: 100%;
    position: relative;
}

.imgFront {
    height: 90vh;
    width: 100%;
    object-fit: cover;
    z-index: -1;
}

/* Centering Text */
.upImgDiv {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -35%);
    width: 700px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    animation: fadeIn 1.5s ease-in-out forwards;
    animation-delay: 0.5s;
}

.para {
    font-size: 17px;
    color: white;
    margin: 10px 0;
    transform: translateY(20px);
    opacity: 0;
    animation: slideUp 1.2s ease-in-out forwards;
    animation-delay: 0.7s;
}

.para2 {
    font-size: 50px;
    color: white;
    margin: 15px 0;
    opacity: 0;
    transform: scale(0.9);
    animation: scaleUp 1.5s ease-in-out forwards;
    animation-delay: 1s;
}

/* Slider Container */
.sliderContainer {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 20px 0;
}

.mySwiper {
    width: 100%;
    height: 400px;
}

.swiperSlide {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
}

.slideImage {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.slideImage:hover {
    transform: scale(1.05);
}

/* Keyframes for Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

@keyframes scaleUp {
    from { transform: scale(0.9); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
}

/* Responsive Design */
@media (max-width: 768px) {
    .upImgDiv {
        width: 300px;
        top: 20%;
    }

    .para2 {
        font-size: 38px;
    }

    .mySwiper {
        height: 300px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .upImgDiv {
        width: 500px;
        top: 25%;
    }

    .para2 {
        font-size: 45px;
    }
}