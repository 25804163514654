.outerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    user-select: none;
   
}

.outerDiv.fadeIn {
    opacity: 1;
}

.confirmationCard {
    background-color: white;
    border-radius: 16px;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
    transform: translateY(50px);
    opacity: 0;
    transition: all 0.8s ease-in-out;
    max-width: 400px;
    width: 90%;
}

.confirmationCard.slideIn {
    transform: translateY(0);
    opacity: 1;
}

.successIcon {
    color: #08cc8b;
    font-size: 4rem;
    margin-bottom: 1.5rem;
    transition: transform 0.5s ease;
}

.successIcon.pulse {
    animation: pulseAnimation 2s infinite;
}

@keyframes pulseAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.title {
    color: #4a4a4a;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.description {
    color: #666;
    margin-bottom: 1.5rem;
    font-size: 1rem;
}

.orderSummary {
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
}

.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #333;
}

.totalRow {
    font-weight: bold;
    color: #08cc8b;
}

.totalAmount {
    font-size: 1.25rem;
}

.cardIcon {
    width: 1.5rem;
    vertical-align: middle;
}

.ratingSection {
    margin-bottom: 1.5rem;
}

.stars {
    display: flex;
    justify-content: center;
    gap: 0.25rem;
}

.starFilled {
    color: #ffd700;
    cursor: pointer;
}

.starEmpty {
    color: #ddd;
    cursor: pointer;
}

.homeButton {
    background-color: #6c63ff;
    color: white;
    padding: 0.75rem 1rem;
    border: none;
    margin-left: 5px;
    margin-bottom: 5px;
    border-radius: 24px;
    font-size: 1rem;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.homeButton:hover {
    transform: translateY(-3px);
}

@media (max-width: 600px) {
    .outerDiv{
        margin:10px 0;
    }
    .confirmationCard {
        width: 100%;
        padding: 1.5rem;
    }

    .title {
        font-size: 1.25rem;
    }

    .totalAmount {
        font-size: 1rem;
    }

    .homeButton {
        width: 100%;
        padding: 0.75rem 1.5rem;
        margin-bottom: 5px;
    }
}
