.colorSwiper {
    width: 100%;
    padding: 10px 0;
    margin-left:4px;
  }
  
  .colorBox {
    border: 2px solid transparent;
    cursor: pointer;
    transition: transform 0.2s ease;
    margin-left: 10px;
   } 
  .colorImage {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
  .colorSelectionDiv {
  margin-bottom: 20px ;
  width: 100%;
  padding: 0 30px;
}
 
.colorBox:hover {
  transform: scale(1.1);
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.selectedColor {
  outline: 1px solid #009578;
  border-radius: 50%;
 }
 

.selectedColorInfo {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}
 
  .skeletonOuter {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    animation: fadeIn 0.5s ease-in-out;
  }
  .shareContainer {
    position: relative;
    display: inline-block;
   }
  
  .shareButton {
    background: #4C928B;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-left: 250%;
  }
  
  .shareButton:hover {
    background: #3a7f7a;
  }
  
  .shareButton.active {
    background: #3a7f7a;
  }
  
  .shareIcon {
    font-size: 1rem;
  }
  
  .shareOptions {
    position: absolute;
    top: 40px;
    right: 60;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    animation: fadeIn 0.3s ease;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .shareOption {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
    text-decoration: none;
    color: black;
    margin-bottom: 10px;
  }
  
  .shareOption:hover {
    background: #f8f9fa;
    transform: translateY(-2px);
  }
  
  .icon {
    font-size: 1.2rem;
  }
  
  
  .copySuccess {
    margin-top: 10px;
    font-size: 0.9rem;
    position: absolute;
    white-space: nowrap;
    color: #CDB59B;
    animation: fadeInOut 3s ease;
  }
  
  @keyframes fadeInOut {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  

  .skeletonContainer {
    display: flex;
    gap: 16px;
    background-color: #f8f8f8;
    margin-left: 196px;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .imageSkeleton {
    flex: 1;
    height: 500px;
    background-color: #e0e0e0;
    background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
    animation: shimmer 1.5s infinite;
    border-radius: 8px;
  }
  
  .detailsSkeleton {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .titleSkeleton,
  .priceSkeleton,
  .descriptionSkeleton,
  .buttonSkeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
  }
  
  .titleSkeleton {
    width: 60%;
    height: 24px;
    background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 4px;
  }
  
  .priceSkeleton {
    width: 30%;
    height: 20px;
    background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 4px;
  }
  
  .descriptionSkeleton {
    width: 100%;
    height: 48px;
    background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 4px;
  }
  
  .buttonSkeleton {
    width: 50%;
    height: 40px;
    background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 8px;
  }
  
   .relatedSkeleton {
    background-color: #f8f8f8;
    padding: 16px;
    border-radius: 8px;
  }
  
  .relatedSkeletonTitle {
    width: 30%;
    height: 24px;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  
  .relatedSkeletonItems {
    display: flex;
    gap: 16px;
  }
  
  .relatedSkeletonItem {
    flex: 1;
    height: 200px;
    background-color: #e0e0e0;
    border-radius: 8px;
  }
 
  
  @media (max-width: 768px) {
    .skeletonOuter {
      padding: 16px;
    }
  
    .skeletonContainer {
      flex-direction: column;
      padding: 10px;
      margin-left: 0px;
    }
  
    .imageSkeleton {
      height: 300px;
      margin-top: 250px;
    }
  
    .detailsSkeleton {
      flex: 1;
    }
  
    .titleSkeleton {
      width: 80%;
    }
  
    .priceSkeleton {
      width: 50%;
    }
    .shareOption{
      cursor: none;
    }
  
    .descriptionSkeleton {
      width: 100%;
      height: 48px;
    }
  
    .buttonSkeleton {
      width: 70%;
    }
  
    .relatedSkeletonItems {
      flex-direction: column;
      gap: 8px;
    }
  
    .relatedSkeletonItem {
      height: 150px;
    }
  }
  
  @media (max-width: 480px) {
    .skeletonOuter {
      padding: 10px;
    }
  
    .imageSkeleton {
      height: 250px;
    }
  
    .titleSkeleton {
      width: 90%;
    }
  
    .priceSkeleton {
      width: 60%;
    }
  
    .descriptionSkeleton {
        width: 100%;
        height: 100px;
    }
  
    .buttonSkeleton {
      width: 80%;
    }
  
    .relatedSkeletonItems {
      flex-direction: column;
    }
  
    .relatedSkeletonItem {
      height: 130px;
    }
    .shareButton{
        margin-left: 150%;
        cursor: none;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.redirectLoader {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.redirectLoader p {
    margin-left: 10px;
    font-size: 16px;
    color: #555;
    font-family: 'Lora', serif;
}



 

.icon {
    display: block;
    margin: auto;
}

.outer {
    /* height: fit-content; */
    width: 99vw;
    /* border: 1px solid red; */
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

.imageDiv {
    height: fit-content;
    width: 29vw;
    min-width: 350px;
    /* border: 1px solid rgb(0, 0, 0); */
}

.detailsDiv {
    height: fit-content;
    min-height: 700px;
    width: 40vw;
    min-width: 500px;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}


.mainImg {
    height: 100%;
    width: 100%;
    /* border: 1px solid red; */
}

.displaiedImg {
    height: 100%;
    width: 100%;
    position: relative;
    object-fit: cover;
    border-radius: 5px;
}

.subImgDiv {
    height: 7.5vw;
    min-height: 90px;
    width: 100%;
    margin-top: 10px;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.subImage {
    height: 100%;
    width: 22%;
    border-radius: 5px;
    /* border: 1px solid red; */
    cursor: pointer;
}

.subImage:hover {
    transform: scale(1.1);
    transition: transform 0.5s;
    background-color: none;
}

.productNameDiv,
.descriptionDiv,
.quantityDiv {
    height: fit-content;
    width: 100%;
    padding: 0 30px;
    /* border: 1px solid red; */
}

.productName {
    font-size: 35px;
    font-family: "Lora", serif;
    font-weight: 500;
}


.descriptionDiv {
    padding: 20px 30px;
}

.incDecBtnDiv {
    height: 35px;
    width: 150px;
    margin-top: 1vw;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    gap: 5px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #f0f0f0;
    border: 1px solid #ddcfc1;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #cdb59b;
    transition: background-color 0.3s ease;
}

.activeButton {
    background-color: #BA9976;  
    color: #fff;  
}
.buttonContainer {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.addToBagBtn {
    background-color: #BA9976;
    color: white;
    padding: 12px 15%;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 8px;
    /* Space between icon and text */
    transition: background-color 0.3s ease;
    cursor: pointer;
    white-space: nowrap;
}
.faicon svg{
 font-size: 50px;

}

.bagIcon {
    padding-left: 50px;
}

.addToBagBtn:hover {
    background-color: #eabb88;
}

.wishlistBtn {
    background-color: white;
    color: #333;
    padding: 10px 8%;
    border: 1px solid #333;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    white-space: nowrap;
}

.wishlistBtn:hover {
    background-color: #f2f2f2;
}



h2,
p {
    display: inline;
    font-family: "Lora", serif;
}



.outerDiv {
    width: 99vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

.formDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.date {
    font-size: 28px;
    font-family: 'Lora', serif;
    color: #EDA841;
}


.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.maindiv {
    width: 200px;
    margin: 10px;
    cursor: pointer;
}

.image img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
}

.details {
    font-size: 18px;
    text-align: center;
    margin-top: 5px;
}


/* New details section under Add to Cart */
.detailsSection {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 95%;
    margin-top: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.detailItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.detailItem:hover {
    transform: scale(1.05);
    background-color: #e0e0e0;
}

.icon {
    font-size: 30px;
    color: #BA9976;
    margin-bottom: 10px;
}

.detailItem p {
    font-size: 13px;
    font-family: 'Lora', serif;
    /* font center */
    text-align: center;
}



/* Contact Us */

.contactSection {
    position: relative;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0px;
    width: 90%;
    max-width: 800px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: fadeIn 1s ease;
    overflow: hidden;
    z-index: 1;
}

.contactSection::before,
.contactSection::after {
    content: '';
    width: 150px;
    height: 150px;
    background-color: #f0a6a6;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
}

.contactSection::before {
    top: -70px;
    left: -8%;
    background-color: #e0e0e0;
}

.contactSection::after {
    bottom: -70px;
    right: -9%;
    background-color: #e0e0e0;
}

.contactSection h3 {
    margin-bottom: 10px;
    font-size: 24px;
    font-family: 'Lora', serif;
    color: #333;
}

.contactSection p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #555;
    font-family: 'Lora', serif;
}

.whatsappLink {
    width: 100%;
}

.whatsappButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(58, 46, 46);
    font-weight: bold;
    border: none;
    border-radius: 9px;
    padding: 10px 130px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    animation: pulse 1.5s infinite;
    white-space: nowrap;
}

.whatsappButton:hover {
    background-color: #e0e0e0;
    transform: scale(1.05);
}

.whatsappIcon {
    margin-right: 20px;
    font-size: 20px;
    color: #BA9976;
    font-weight: bold;
    
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-5px);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

/* Basic responsive adjustments */
.detailsDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    max-width: 100%;
}



/* Add verified image animation */

.productNameDiv {
    display: flex;
    align-items: center;
    flex-wrap: wrap; /* Flex-wrap for responsive */
}

.productNameDiv h2 {
    font-size: 1.5rem; /* Adaptable font-size */
    margin: 0;
}

.productNameDiv img {
    width: 100px;  /* Relative size for responsiveness */
    height: auto;
    margin-left: 10px;
    margin-top: 5px;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards, scaleUp 1s ease-in-out forwards;
    animation-delay: 0.5s; /* Delayed animation for smoother appearance */
    color: #009578;
}

/* Keyframes for Animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes scaleUp {
    0% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
}
.animateCart {
    animation: bounce 0.5s ease;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-5px);
    }

    100% {
        transform: translateY(0);
    }
}

 @keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@media (max-width:768px) {
    .outerDiv {
        width: 100vw;
    }

    .formDiv {
        width: 90%;
    }

    .input {
        height: 35px;
        margin: 5px 0;

    }

    .activeInput {
        height: 35px;
        margin: 5px 0;

    }

    .date {
        font-size: 28px;
    }

    .maindiv {
        height: fit-content;
        width: 40vw;
        /* background-color: bisque; */
        padding: 10px;
    }

    .details {
        font-size: 10px;
    }

    .detailsDiv {
        padding: 10px;
    }

    .productName {
        font-size: 1.5rem;
    }

    .icon {
        font-size: 25px;
        cursor: none;
    }

    .buttonContainer {
        gap: 5px;
    }

    .wishlistBtn {
        font-size: 13px;
        
    }

    .addToBagBtn {
        font-size: 13px;
        margin-right: 35px;
        padding: 10px 10%;
    }
   

    .contactSection {
        padding: 20px;
    }

    .whatsappButton {
        padding: 10px 40px;
        font-size: 14px;
        cursor: none;
        white-space: nowrap;
    }
   

    .contactSection::before {
        top: -25px;
        left: -4%;
        background-color: #e0e0e0;
    }

    .contactSection::after {
        bottom: -20px;
        right: -9%;
        background-color: #e0e0e0;
    }


    .contactSection::before,
    .contactSection::after {
        width: 70px;
        height: 70px;
    }

    .verifiedIcon {
        width: 40px;
        height: 40px;
    }
}

@media (max-width:863px) {
    .outerDiv {
        gap: 1vw;
    }

    .imageDiv {
        margin: 1vw 0;
        min-width: 90vw;
    }

    .detailsDiv {
        width: 95%;
        min-width: unset;
    }

    .productNameDiv {
        padding: 6px;
    }

    .productName {
        font-size: 25px;
        text-align: center;
    }
    .colorSelectionDiv{
        padding: 6px;
    }
    .quantityDiv {
        padding: 6px;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .subImgDiv {
        min-height: 20vw;
        width: 100%;
        margin-top: 5px;
    }

    .descriptionDiv {
        padding: 10px;
    }

    .subImage {
        cursor: none;
    }

    .detailsDiv {
        margin-top: 4vw;
        height: fit-content;
        min-height: 20px;
    }

    .detailsSection {
        flex-direction: row;
        gap: 0px;
        width: 100%;
        margin: 9px auto;
        background-color: #fff;

    }

    .detailItem {
        padding: 10px;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        border-radius: 0;
        cursor: none;
    }

    .detailItem:last-child {
        border-bottom: none;
    }

    .detailItem p {
        font-size: 8px;
        font-family: 'Lora', serif;
        margin-left: 10px;
        margin-top: 7px;
    }

    .icon {
        font-size: 24px;
        margin: 0;
    }

    .detailItem a {
        font-size: 14px;
        color: #000;
        text-decoration: none;
        font-weight: bold;
    }

    .formDiv {
        margin-bottom: 20px;
    }

    .container {
        justify-content: space-around;
    }

    .maindiv {
        width: 43%;
        cursor: none;
        padding: 0;
    }
    .colorBox{
        cursor: none;
        gap: 5px;
    } 
}

@media (max-width:1024px) {
    .buttonContainer {
        gap: 5px;
    }
    .wishlistBtn {
        font-size: 13px;
        cursor: none;
    }

    .addToBagBtn {
        font-size: 14px;
        cursor: none;
    }

    .contactSection p {
        font-size: 14px;
    }
}
@media (max-width: 600px) {
     
    .productNameDiv img {
        width: 80px; 
        margin-bottom: 10px;
    }

    .productNameDiv h2 {
        font-size: 1.2rem;
    }
    .button {
        width: 35px;
        height: 35px;
      }

      .addToBagBtn{
        padding: 10px 8%;
        font-size: 14px;
      }
      .wishlistBtn{
        padding: 10px 5%;
        font-size: 14px;
        margin-left: 50px;
      }
}

@media (min-width: 600px) and (max-width: 900px) {
    .productNameDiv img {
        width: 90px;
    }

    .productNameDiv h2 {
        font-size: 1.3rem;
    }
}

/* Desktops (min-width: 900px) */
@media (min-width: 900px) {
    .productNameDiv img {
        width: 120px;
        margin-bottom: 15px;
    }

    .productNameDiv h2 {
        font-size: 1.5rem;
    }
}