.productForm {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
}

.productForm h2 {
    display: block;
    text-align: center;
    margin-bottom: 20px;
}

.formGroup {
    margin-bottom: 15px;
}

.formGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.formGroup input,
.formGroup textarea {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.formGroup textarea {
    resize: vertical;
}

.submitButton {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.submitButton:hover {
    background-color: #218838;
}