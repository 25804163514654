.skeletonContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2vw;
  margin-top: 1.4rem;
}

.skeletonCard {
  width: 15vw;
  height: 250px;
  border-radius: 10px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: shimmer 1.5s infinite ease-in-out, slideInLeft 0.5s ease-out;
}

.skeletonImage {
  width: 100%;
  height: 60%;
  background: linear-gradient(90deg, #e0e0e0 25%, #d6d6d6 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite ease-in-out;
}

.skeletonTitle {
  height: 15%;
  background: linear-gradient(90deg, #e0e0e0 25%, #d6d6d6 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  margin: 5px 10px;
  border-radius: 5px;
  animation: shimmer 1.5s infinite ease-in-out;
}

.skeletonPrice {
  height: 10%;
  background: linear-gradient(90deg, #dcdcdc 25%, #c8c8c8 50%, #dcdcdc 75%);
  background-size: 200% 100%;
  margin: 5px 10px;
  border-radius: 5px;
  animation: shimmer 1.5s infinite ease-in-out;
}

.skeletonReadMoreContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  width: 100%;
}

.skeletonReadMoreButton {
  width: 200px;
  height: 40px;
  border-radius: 5px;
  background: linear-gradient(90deg, #e0e0e0 25%, #d6d6d6 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite ease-in-out;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .skeletonContainer {
    gap: 5vw;
  }
  .skeletonCard {
    width: 40vw;
  }
}


.icon {
    display: block;
    margin: auto;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2vw;
    padding: 1vw;
    cursor: pointer;
    margin-top: 1.4rem;
}

.containerMain{
    text-align: center;
  padding: 20px;
}
.allSariHeading {
    font-size: 2.5rem;
    font-weight: 400;
    font-family: Tiemann, serif;
    color: #ba9976;
    margin-bottom: 1rem;
    animation: fadeIn 1s ease-in-out;
}



.maindiv {
    width: 15vw;
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    opacity: 0;   
    transform: translateX(-100%);   
}

.maindiv.visible {
    animation: slideInLeft 0.8s ease-in-out forwards;
 }

.maindiv:hover {
    transform: scale(1.08);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 150, 120, 0.3);
 }

 
.image {
    height: auto;
}

.image img {
    width: 100%;
    height: auto;
}
.outOfStockBadge {
    position: absolute;
    right:0px;
    background-color: red;
    color: white;
    padding: 3px 6px;
    border-radius: 5px;
    font-size: 12px;
}

 
.details p {
    font-weight: bold;
    font-size: 15px;
}

.details {
    text-align: center;
    padding: 5px 5px;
    font-size: 14px;
    font-family: 'Futura', sans-serif;
    letter-spacing: 1px;
    color: #333;
     border-radius: 0 0 10px 10px;
    transition: background-color 0.3s ease;
}
.preBooking {
    margin-top: 5px;
}
.preBooking span {
    color: #007a63;
    font-weight: bold;
}
.addToCartButton {
    background-color: #009578;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.addToCartButton:hover {
    background-color: #007a63;
}


.readMoreContainer {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.readMoreButton {
    background-color: #ba9976;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    font-family: Futura, sans-serif;
    transition: background-color 0.3s ease;
}


.readMoreButton:hover {
    background-color: #dab187;
    color: #333;
    transition: background-color 0.3s ease;
    animation: readMoreButton 0.5s ease-in-out;

}

.readMoreButton:active{
    background-color: #ba9976;
    transition: background-color 0.3s ease;
    animation: readMoreButton 0.5s ease-in-out;
}

@media (max-width: 768px) {
    .allSariHeading{
        font-size: 1.9rem;
        font-family: Tiemann, serif;
        font-weight: 400;

    }
    .maindiv {
        width: 40vw;
         transition: none;
    }

    .maindiv:hover {
        transform: none;
        box-shadow: none;
    }
    .outOfStockBadge{
        font-size: 9px;
        padding: 2px 4px;
    }
    .details {
        font-size: 12px;
    }

    .container {
        cursor: none;
    }
    .preBooking span{
        font-size: 12px;
        font: small-caption;
        font-weight: bold;
    }
}

@media (max-width: 480px) {
    .allSariHeading {
      font-size: 1.7rem;
      white-space: nowrap;
    }
}