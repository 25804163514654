.section__padding {
    padding: 4rem 4rem;
}

.footer {
    padding: 10px 20px;
    background-color: white;
    font-family: "Poppins", sans-serif;
    user-select: none;
}

.sb__footer {
    display: flex;
    flex-direction: column;
}

.sb__footerLinks {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}

.sb__footerLinksDiv {
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: #BA9976;
}

.sb__footerLinksDiv p:hover {
    color: #009578;
    font-weight: 600;
}

.sb__footerLinksDiv p:active {
    transform: scale(0.95);
}

a {
    color: #BA9976;
    text-decoration: none;
}

.socialmedia {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.socialIcon {
    font-size: 24px;
    color: #BA9976;
    transition: transform 0.3s ease, color 0.3s ease;
    cursor: pointer;
}

.socialIcon:hover {
    transform: scale(1.2);
    color: #009578;
}

.sb__footerLinksDiv h4 {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.9rem;
    color: #BA9976;
}

.sb__footerLinksDiv p {
    font-size: 12px;
    line-height: 15px;
    color: #BA9976;
    margin: 0.5rem 0;
    cursor: pointer;
}

.footerTop {
    display: flex;
    justify-content: space-around;
    text-align: center;
    padding: 10px 0;
    color: #BA9976;
}

.footerItem {
    flex: 1;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.footerItem::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: #E5E5E5;
}

.footerItem:last-child::after {
    display: none;
}

.footerIcon {
    font-size: 36px;
    color: #BA9976;
    margin-bottom: 10px;
    transition: transform 0.3s ease;
}

.footerItem p {
    font-size: 14px;
    color: #BA9976;
    font-weight: 500;
}

h5 {
    font-family: Arial, Helvetica, sans-serif;
}

.footerItem:hover .footerIcon {
    transform: scale(1.2);
}

.footerIcon:hover {
    color: #9f8262;
}

.footerShip {
    text-align: center;
    margin: 10px 0;
    font-size: 14px;
    color: #BA9976;
}

.sb__footerBelow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
    padding: 10px;
    border-top: 1px solid #ddd;
}

.sb__footerBelowLinks {
    display: flex;
    flex-direction: row;
}

.sb__footerBelowLinks p {
    font-size: 13px;
    line-height: 15px;
    margin-left: 2rem;
    color: #BA9976;
    font-weight: 600;
    cursor: pointer;
}

.sb__footerBelowLinks p:hover {
    color: #009578;
    font-weight: 600;
}

.sb__footerBelowLinks p:active {
    transform: scale(0.95);
}

.sb__footerCopyright p {
    font-size: 13px;
    line-height: 15px;
    color: #BA9976;
    font-weight: 600;
}

@media screen and (max-width: 850px) {
    .sb__footerLinks {
        justify-content: normal;
    }

    .sb__footerHeading h1 {
        font-size: 44px;
        line-height: 50px;
    }

    .sb__footerBelow {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .sb__footerBelowLinks {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .sb__footerBelowLinks div {
        margin: 0 0.5rem;
        position: relative;
    }

    .sb__footerBelowLinks div:not(:last-child)::after {
        content: '|';
        position: absolute;
        right: -10px;
        color: #BA9976;
    }

    .sb__footerBelowLinks p {
        margin: 0.5rem 0;
        font-size: 13px;
        color: #BA9976;
        font-weight: 600;
    }

    .sb__footerCopyright p {
        margin-top: 1rem;
    }

}

@media screen and (max-width: 550px) {
    .sb__footerHeading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .sb__footerLinks div {
        margin: 1rem 0;
    }

    .sb__footerLinksDiv p {
        cursor: none;
    }

    .sb__footerBtn p {
        font-size: 14px;
        line-height: 20px;
    }

    .footerTop {
        text-align: center;
    }

    .footerItem::after {
        display: none;
    }

    .footerLinks {
        flex-direction: column;
        text-align: center;
    }

    .sb__footerBelow {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0.5rem 1rem;
    }

    .sb__footerBelowLinks {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .sb__footerBelowLinks div {
        margin: 0 0.3rem;
        position: relative;
    }

    .sb__footerBelowLinks div:not(:last-child)::after {
        content: '|';
        position: absolute;
        right: -8px;
        color: #BA9976;
    }

    .sb__footerBelowLinks p {
        font-size: 12px;
        color: #BA9976;
        font-weight: 600;
        margin: 0.3rem 0;
        cursor: none;
    }

    .sb__footerCopyright p {
        margin-top: 1rem;
        font-size: 12px;
    }
}

@media screen and (max-width: 400px) {
    .footerItem::after {
        display: none;
    }

    .footerShip p {
        font-size: 12px;
    }

    .sb__footerHeading h1 {
        font-size: 27px;
        line-height: 38px;
    }

    .sb__footerBelow {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0.3rem 0.8rem;
    }

    .sb__footerBelowLinks {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .sb__footerBelowLinks div {
        margin: 0 0.2rem;
        position: relative;
    }
    

    .sb__footerBelowLinks div:not(:last-child)::after {
        content: '|';
        position: absolute;
        right: -5px;
        color: #BA9976;
    }



    .sb__footerBelowLinks p {
        font-size: 11px;
        color: #BA9976;
        font-weight: 600;
        margin: 0.2rem 4px;
        cursor: none;
        gap: 0.5rem;
    }

    

    .sb__footerCopyright p {
        margin-top: 1rem;
        font-size: 11px;
    }

    .footerItem {
        margin: 5px;
    }
}
