.carousel {
    position: relative;
    max-width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3rem;
    overflow: hidden;
    animation: fadeInScale 0.5s ease-in-out;
  }

  .heading {
    font-size: 2.5rem;
    font-weight: 400;
    color: #ba9976;
    margin-bottom: 1rem;
    font-family: Tiemann, serif;
    animation: fadeIn 1s ease-in-out;
  }
  
  .reelContainer {
    position: relative;
    width: 60%;
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }
  
  .videoPlayer {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    bottom: 10%;
    left: 10%;
    z-index: 2;
    color: white;
  }
  
  .title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .controls {
    display: flex;
    gap: 10px;
  }
  
  .controls button {
    background-color: rgba(244, 166, 166, 0.5);
    border: none;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .controls button:hover {
    transform: scale(1.1);
    background-color: rgba(116, 68, 68, 0.8);
  }
  
  .likeButton {
    background-color: rgba(244, 166, 166, 0.5);
  }
  
  .likeAnimation {
    animation: likePulse 0.5s ease;
  }
  
  .shopButton {
    background-color: rgba(76, 175, 80, 0.5);
  }
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: whitesmoke;
    background: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    z-index: 3;
  }
  
 

  .arrow:focus {
    outline: none;
  }
  .arrow:focus-visible {
    outline: 2px solid #d19c64;

  }
 
  
  .left {
    left: 10px;
  }
  
  .right {
    right: 10px;
  }
  
  @keyframes fadeInScale {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes likePulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
  }
  
  @media (max-width: 768px) {
    .heading {
        font-size: 1.9rem;
        font-family: Tiemann, serif;
        font-weight: 400;
    }
    .reelContainer {
      width: 90%;
    }
  
    .title {
      font-size: 1.2rem;
    }
    .arrow {
      font-size: 1.5rem;
      cursor: none;
    }
  
    .controls button {
      padding: 6px 10px;
     }
  }

  @media (max-width: 480px) {
    .heading {
        font-size: 1.4rem;
        padding: 0 12px;
        white-space: nowrap;
        margin-bottom: 1rem;
      }
    .reelContainer {
      width: 100%;
    }
  
    .overlay {
      bottom: 5%;
      left: 5%;
    }
    .title {
      font-size: 1rem;
    }
    .arrow {
      font-size: 1rem;
    }
  
    .controls button {
      padding: 4px 8px;
    }
    
  }
  