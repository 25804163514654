.contactContainer {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    user-select: none;
  }
  
  .contactForm {
    margin-top: 20px;
  }
  
  .contactForm label {
    display: block;
    margin-bottom: 5px;
    font-family: Futura, sans-serif;
    color: #cc6d08; 
  }
  
  .contactForm input,
  .contactForm textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: Futura, sans-serif;
    color: #000;
  }
  
  .contactForm button {
    background-color: #BA9976;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contactForm button:hover {
    background-color: #cc6d08;
  }
  
  .contactUsInfo {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .contactTitle {
    margin-bottom: 10px;
  }
  
  .contactTitle h3 {
    font-family: 'Tiemann', serif;
    margin-bottom: 10px;
    color: #cc6d08;
  }
  
  .contactDetails {
    margin-bottom: 20px;
    padding-left: 20px;
  }
  
  .contactDetails h4 {
    font-family: 'Tiemann', serif;
    margin-bottom: 10px;
    color: #aa6e2e;
  }
  
  .contactDetails ul {
    font-family: Futura, sans-serif;
    padding: 10px 10px 10px 20px;
  }
  
  .contactDetails ul li {
    margin-bottom: 5px;
    color: #000;
  }
  
  .colorfulSaree,
  .latestTrend {
    margin-bottom: 40px;
  }
  
  .colorfulSaree h3,
  .latestTrend h3 {
    font-family: 'Tiemann', serif;
    margin-bottom: 10px;
    color: #cc6d08;
  }
  
  .colorfulSaree p,
  .latestTrend p {
    font-family: Futura, sans-serif;
    margin-bottom: 10px;
    color: #000;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fadeInPage {
    animation: fadeIn 1s ease-in-out;
  }
  
  .fadeIn {
    animation: fadeIn 1.5s ease-in-out;
  }
  
  @media (max-width: 768px) {
    .contactContainer {
      padding: 20px;
    }
  
    .contactForm input,
    .contactForm textarea {
      padding: 8px;
    }
  
    .contactForm button {
      padding: 8px 16px;
    }
  
    h2 {
      font-size: 20px;
    }
  
    .contactDetails h4 {
      font-size: 16px;
    }
  
    .contactContainer p,
    .colorfulSaree p,
     .latestTrend p {
      font-size: 14px;
    }
  }
  