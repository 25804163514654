.pnf {
    display: flex;
    min-height: 65vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    animation: fadeIn 1.5s ease-in-out;
}

.name {
    font-size: 1.5rem;
    font-weight: 400;
    color: #333025;
    margin-bottom: 20px;
    font-family: Futura, sans-serif;
}

.name span {
    font-size: 1.5rem;
    font-weight: 700;
    color: #BA9976;
    margin-bottom: 20px;
    font-family: Futura, sans-serif;
}

.title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333025;
    margin-bottom: 20px;
    font-family: Futura, sans-serif;
}

.pnfIcon {
    font-size: 7rem;
    color: #BA9976;
    margin-bottom: 20px;
    animation: bounce 2s infinite;
}

.subtitle {
    font-size: 1.2rem;
    font-weight: 400;
    color: #333025;
    padding-bottom: 20px;
}

.pnfBtn {
    padding: 10px 20px;
    background-color: #333025;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.pnfBtn:hover {
    background-color: #BA9976;
    color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease;
    transform: scale(1.1);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

@media screen and (max-width: 768px) {
    .name {
        font-size: 1.2rem;
    }
    .name span {
        font-size: 1.2rem;
    }
    .title {
        font-size: 1.3rem;
        white-space: nowrap;
    }
    .subtitle {
        font-size: 1rem;
    }
    .pnfBtn {
        padding: 8px 16px;
        font-size: 0.8rem;
    }   

    .pnfIcon {
        font-size: 6rem;
    }
}
