.upHeaderUpper{
    height: 20px;
    /* width: 100vw; */
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid wheat;
    user-select: none;
}
     .no-scroll {
        overflow: hidden;
        height: 100%;
        width: 100%;
      }
      
      body, html {
        height: 100%;
        margin: 0;
      }
      
  
   .marquee {
    font-size: 14px;
    color: #ba9976;
    font-weight: bold;
    font-family: Tiemann, serif;
    animation: marquee 10s linear infinite;
    white-space: nowrap;
    overflow: hidden;
  } 
  
.header {
    animation: fadeIn 1s ease-in-out;
    overflow: hidden;
    user-select: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.upHeader {
    height: 30px;
    /* width: 100vw; */
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid wheat;
    user-select: none;
}

.inUpheader {
    height: 100%;
    width: 69%;
    /* background-color: rgb(13, 16, 18); */
    display: flex;
    align-items: center;
    justify-content: flex-end;

}

.menuicon {
    display: none;
}

.listUpheaderLeft {
    font-family: Futura, sans-serif;
    font-weight: 400;
    font-size: 17px;
    width: 70%;
    color: black;
    display: flex;
    align-items: center;
    gap: 1.8em;
    list-style-type: none;
    transition: transform 0.3s ease;
    /* z-index: 10; */
    position: absolute;
    background-color: #f9f9f9;

}
.mobileMenuContainer {
    overflow-y: auto;
    max-height: 100vh; /* Ensure it doesn’t exceed the viewport height */
  }

.navButton {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    white-space: nowrap;
    padding: 0;
    transition: color 0.3s ease, transform 0.3s ease;

}
.navButton1{
    position: relative;
    color: inherit;
    border: none;
    background-color: #f9f9f9;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    white-space: nowrap;
    transition: color 0.3s ease, transform 0.3s ease;
}
.dropdownItem {
    position: relative;
  }
.navButton:focus {
    outline: none;
}
.navButton1:focus {
    outline: none;
}

.dropdownIcon{
    font-size: 15px;
    margin-left: 5px;
    transition: transform 0.3s ease;  
  }
  
  .dropdownIcon.open {
    transform: rotate(180deg);  
  }
  
   .dropdownMenu {
    position: absolute;
    top: 100%;
    background: #ba9976;
    left: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
    z-index: 1000;
    width: 180px;
    overflow: hidden;
    display: none;
  }
  .nestedDropdownMenu { 
    margin-left: 8px;
    border-radius: 8px;
    min-width: 200px;
  }
  .nestedDropdownMenu.show button {
    color: #ffffff;
  }
  
  .dropdownMenu.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    display: block;
  }
  
  .dropdownMenu li {
    list-style: none;
    margin: 0;
  }
  
   .dropdownMenu button {
    position: relative;
    display: block;
    width: 100%;
    padding: 10px 20px;
    text-align: left;
    background: none;
    border: none;
    color: #ffffff;
    font-weight: 400;
    font-family: 'Futura', sans-serif;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    }

    .dropdownMenu.show button.navButton2::after{
        content: "";
        display: block;
        width: 40%;
        height: 0.7px;
        background-color: rgb(93, 69, 10);
        transition: 0.3s ease-in-out;
    }
    .dropdownMenu button p{
        white-space: nowrap;
        overflow: hidden;
    }
    .dropdownMenu button:hover {
        background: none;
        color: #e6e5e3df;
        border-radius: 10px;
    }
    .dropdownMenu button.active{
        background: none;
         border-radius: 10px;
    }


    .listUpheaderLeft li .navButton:hover::after,
.listUpheaderLeft li .navButton.active::after {
    content: "";
    display: block;
    width: 40%;
    height: 2px;
    background-color: rgb(93, 69, 10);
    transition: 0.3s ease-in-out;
}


.listUpheaderLeft li .navButton {
    color: black;
}
.listUpheaderLeft li .navButton1 {
    color: black;
}

.listUpheaderLeft li .navButton:hover,
.listUpheaderLeft li a.active {
    color: #cc6d08;
    transform: scale(1.1);
}
.listUpheaderLeft li .navButton1:hover{
    color: #cc6d08;
    transform: scale(1.1);
}

.listUpheaderRight {
    display: block;
    font-size: 13px;
    /* background-color: rgb(255, 255, 255); */
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 1em;
    list-style-type: none;
}

.listUpheaderRight a {
    color: black;
}

/* .stick {
    position: fixed;
    top: 0px;
} */

.listUpheaderRight a,
.listUpheaderRight svg {
    color: black;
    transition: color 0.3s ease, transform 0.3s ease;
}

.listUpheaderRight a:hover,
.listUpheaderRight svg:hover {
    color: #009578;
    transform: scale(1.1);
}



.mainHeader {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    top: 0;
    z-index: 1000;
}

.inMainheader {
    height: 100%;
    width: 80%;
    /* background-color: rgb(13, 16, 18); */
    display: flex;
    justify-content: space-between;
}

.logoDiv {
    height: 100%;
    min-width: 160px;
    /* border: 1px solid rgb(25, 13, 13); */
    display: flex;
    align-items: center;
    justify-content: left;
}

.logo {
    height: 100%;
    width: auto;
    font-family: Futura, sans-serif;
    font-weight: 100;
    transition: transform 0.3s ease, color 0.3s ease;
}

.logo:hover {
    transform: scale(1.1);
}

.mainList {
    height: 100%;
    width: 90%;
    /* border: 1px solid rgb(200, 0, 0); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-family: Futura, sans-serif;
    list-style-type: none;
    font-size: 13px;
    letter-spacing: 2px;
    /* padding: 10px; */
    cursor: pointer;
}

.mainList li {
    margin: 10px;
    transition: color 0.3s ease, transform 0.3s ease;
}

.mainList li:hover {
    color: #009578;
    transform: scale(1.1);
    font-weight: 600;
}

.mainList li a {
    color: black;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;
}

.mainList li a:hover {
    color: #009578;
    transform: scale(1.1);
}




.li {
    margin: 10px;
}

.profileAndCart {
    height: 100%;
    /* width: 100px; */
    /* border: 1px solid rgb(163, 25, 25); */
    font-size: 35px;
    /* color: rgb(255, 255, 255); */
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 1.2em;
    list-style-type: none;
    margin-right: 20px;
}

.search {
    position: relative;
    cursor: pointer;
    /* border: 1px solid black; */
    font-size: 25px;
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
}

.clickableIcon {
    position: relative;
    cursor: pointer;
    /* border: 1px solid black; */
    font-size: 24px;
    font-weight: 400;
    font: bold;
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
}

.clickableIcon:hover {
    transform: scale(1.1);
    color: #009578;
}

.iconWrapper {
    height: fit-content;
    position: relative;
    display: inline-block;
    margin-top: 0px;
    /* border: 1px solid black; */
}

.tooltip {
    visibility: hidden;
    background-color: #1e3a63;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    padding: 5px 10px;
    position: absolute;
    top: 40px;
    left: 25px;
    transform: translateY(-50%);
    white-space: nowrap;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 14px;
}



.tooltip::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -6px;
    transform: translateY(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent transparent #1e3a63;
}

.iconWrapper:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 1920px) {
    .listUpheaderLeft {
        font-size: 15px;
    }
}

@media (max-width:1280px) {
    .listUpheaderLeft {
        font-size: 12px;
    }
}

@media (max-width:896px) {
    .mainHeader {
        height: 130px;
    }
}

@media (max-width:768px) {
    .account {
        display: none;
    }
    .no-scroll {
        overflow: hidden;
       }


    .menuicon {
        display: block;
        font-size: 28px;
        cursor: pointer;
        z-index: 999;
    }

    .li {
        display: none;
        margin: 20px 0;
        width: 100%;
        text-align: left;
        padding-left: 20px;
    }

    .menuicon {
        display: block;
        font-size: 28px;
        cursor: none;
        z-index: 999;
    }

    .upHeader {
        display: block;
        border-bottom: none;
        margin-top: -25px;
    }

    .listUpheaderLeft li .navButton:hover,
    .listUpheaderLeft li a.active {
        color: #cc6d08;
        transform: scale(0.6);
    }

    .listUpheaderLeft li .navButton:hover::after,
    .listUpheaderLeft li .navButton.active::after {
        display: none;
    }

    .listUpheaderLeft li .navButton1:hover {
        color: #cc6d08;
        transform: scale(1);
    }

    .listUpheaderLeft li .navButton1:hover::after,
    .listUpheaderLeft li .navButton1.active::after {
        display: none;
    }



    .mainHeader {
        height: 70px;
    }

    .mainList {
        display: none;
        cursor: none;
    }

    .inMainheader {
        width: 100vw;
    }


    .listUpheaderLeft.open,
    .listUpheaderRight.mobile {
        transform: translateX(0);
    }

    .listUpheaderLeft.open {
        position: fixed;         
        top: 0;                  
        right: 0;               
        height: 100vh;        
        overflow-y: auto;       
        overflow-x: hidden;      
        background-color: white;  
        z-index: 100;     
      }

    .tooltip {
        display: none;
    }

    inUpheader {
        width: 100%;
        display: flex;
    }


    .listUpheaderLeft {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 85%;
        overflow-y: auto; 
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 80px;
        transform: translateX(100%);
        z-index: 10;
        gap: 0.2em;
        transition: transform 0.3s ease-in-out;
        overflow-y: auto;  
        max-height: 100vh;
    }

    .listUpheaderLeft .navButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        box-sizing: none;
        height: auto;
        padding: 5px 20px;
        margin-bottom: 10px;
        text-align: left;
        font-size: 20px;
        color: black;
        transition: 0.7s ease-in-out;
        cursor: none;
    }
    .listUpheaderLeft .navButton1 {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        box-sizing: none;
        height: auto;        
        margin-bottom: 5px;
        margin-left: 17px;
        text-align: left;
        font-size: 20px;
        color: black;
        background: none;
        transition: 0.7s ease-in-out;
        cursor: none;
    }
    .listUpheaderLeft .navButton2{
        padding: 3px 1px;
        border-radius: 8px;
        box-sizing: none;
        height: auto;
        margin-bottom: 5px;
        margin-left: 21px;
        text-align: left;
        font-size: 20px;
        transition: 0.7s ease-in-out;
        cursor: none;
    }
    
    .dropdownMenu {
        position: static;
        width: 100%;  
        background: none;
        border-radius:10px;  
        border: none;
        background-color: none;
        background-color: none;
        box-shadow: none;
        overflow: hidden;
    }
     
    .nestedDropdownMenu {
        position: static;
        width: 100%;
        margin-left: 0;
        background: none;
        box-shadow: none;
      }
        
    .dropdownMenu button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 20px;
        padding: 10px 20px;
        width: 100%;
        text-align: left;
        transition: background 0.3s ease, color 0.3s ease;
      }
    
      .dropdownMenu button:hover {
        background: #ba9976;  
        background-color: none;
        color: #fff; 
        border-radius: 8px;
      }

    .dropdownMenu button p{
        white-space: nowrap;
        overflow: hidden;
    }

    .dropdownItem {
        position: relative;
    }
    .dropdownMenu.show button{
        padding: 10px 10px;
    }
    .dropdownMenu.show button {
        margin-left: 30px; 
        color: #ba9976;
        font-weight: 400;
        font-family: 'Futura', sans-serif;
        cursor: none;
        transition: 0.3s ease-in-out;
    }
    .dropdownMenu.show button.navButton2{
        margin-left: 33px; 
        border: none;
        font-weight: 400;
        padding: 3px 7px;
        font-family: 'Futura', sans-serif;
        cursor: none;
        transition: 0.3s ease-in-out;
    }
    .dropdownMenu.show button.navButton2::after{
        content: "";
        display: block;
        width: 40%;
        height: 2px;
        background-color: rgb(93, 69, 10);
        transition: 0.3s ease-in-out;
    }
    .dropdownMenu.show button p{
        white-space: nowrap;
        overflow: hidden;
    }

     
    .dropdownMenu.show button:hover {
        background: none;
        color: #e98316;
    }
    
    .dropdownMenu .show{
        background-color: none;
     }
     .nestedDropdownMenu button { 
        font-size: 18px;
         
      }
      .nestedDropdownMenu.show{
        background-color: none;
        margin-left:20px;
      }
      .nestedDropdownMenu.show button {
        margin-left: 30px; 
        color: #e88114;
        font-weight: 400;
        font-family: 'Futura', sans-serif;
        cursor: none;
        transition: 0.3s ease-in-out;
      }
        .nestedDropdownMenu.show button p{
            white-space: nowrap;
            overflow: hidden;
        }
        .nestedDropdownMenu.show button:hover {
            background: none;
            color: #e88114;
        }
        
    

    .listUpheaderLeft.show {
        transform: translateX(0);
    }

     .menuOverlay {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(224, 230, 229, 0.5);
        z-index: 100;
    }

    .listUpheaderRight {
        position: fixed;
        bottom: 50px;
        display: none;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 130%;
        overflow-y: auto;
         z-index: 10;
        transform: translateX(100%);
        transition: transform 0.95s ease-in-out;
    }
/* 
    .listUpheaderRight .iconWrapper {
        font-size: 35px;
        color: black;
        cursor: pointer;
        transition: color 0.3s ease;
        position: static;
    }

    .listUpheaderRight .iconWrapper:hover {
        color: #666;
    } */



    .profileAndCart {
        height: 100%;
        width: 600px;
        /* border: 1px solid rgb(163, 25, 25); */
        font-size: 28px;
        /* color: rgb(255, 255, 255); */
        display: flex;
        align-items: center;
        justify-content: right;
        gap: 0.7em;
        list-style-type: none;
    }


    .menuOverlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(224, 230, 229, 0.5);
        z-index: 9;
    }

    .menuOverlay.show {
        display: block;
    }

    .clickableIcon {
        cursor: none;
    } 

     

}