.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalContent {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .modalContent h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .reviewForm input,
  .reviewForm textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .reviewForm textarea {
    resize: none;
    height: 80px;
  }
  
  .formButtons {
    display: flex;
    justify-content: space-between;
  }
  
  .formButtons button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 0.95rem;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .formButtons button:first-child {
    background: #ccc;
    color: #333;
  }
  
  .formButtons button:last-child {
    background: #BA9976;
    color: #fff;
  }
  
  .formButtons button:hover {
    opacity: 0.9;
  }
  
.reviewsSection {
  padding: 20px;
  background: #f9f9f9;
  font-family: "Arial", sans-serif;
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h2 {
  font-size: 1.8rem;
  font-weight: 400;
  font-family: Tiemann, serif;
  color: #333;
}

.reviewButton {
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.reviewButton:hover {
  background-color: #555;
}

.subHeader{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 400;
  font-family: Tiemann, serif;
  margin-bottom: 1rem;
  color: #8f7253;
}

/* Scroll Wrapper */
.scrollWrapper {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.scrollButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #444;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 10;
  transition: background-color 0.3s ease;
}

.scrollButton:hover {
  background-color: #666;
}

.leftButton {
  left: 10px;
}

.rightButton {
  right: 10px;
}

/* Horizontal Scroll Area */
.reviewsContent {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 10px 0;
  margin: 0 auto;
  scrollbar-width: none;
}

.reviewsContent::-webkit-scrollbar {
  height: 5px;
}

.reviewsContent::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.reviewsContent::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Individual Card Styling */
.card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 250px;
  max-width: 300px;
  flex-shrink: 0;
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Stars */
.stars {
  color: #ffc107;
  display: flex;
  gap: 5px;
}

/* Card Content */
.title {
  font-size: 1.1rem;
  font-weight: bold;
  color: #222;
  margin: 0;
}

.review {
  font-size: 0.9rem;
  color: #555;
  margin: 0;
  line-height: 1.4;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.userImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.userName {
  font-size: 0.9rem;
  color: #333;
}

 @media (max-width: 768px) {
  .scrollButton {
    display: none;
  }
  .header {
    flex-direction: column;
    gap: 20px;
  }
  .subHeader {
    justify-content: center;
  }
  .subHeader h3 {
    font-size: 1.2rem;
  }
  .subHeader button {
    padding: 8px 15px;
    font-size: 0.85rem;
  }
  .reviewsSection {
    padding: 15px;
  }

  .reviewsContent {
    gap: 10px;
    scrollbar-width: thin;
    scrollbar-color: #ccc #f9f9f9;
  }

  .card {
    width: 100%;
    min-width: auto;
  }
}

@media (max-width: 480px) {
    
    .header {
        text-align: center;  
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
      }
    
      .header h2 {
        font-size: 1.3rem; 
        font-weight: bold;
        color: #333;  
        margin-bottom: 10px;
        white-space: nowrap;
      }
    
       .reviewButton {
        display: inline-block;
        padding: 12px 20px;
        font-size: 0.95rem;  
        font-weight: 600;
        color: #fff;  
        background: linear-gradient(135deg, #222, #333);  
        border: none;
        border-radius: 25px; 
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);  
        transition: all 0.3s ease;
        cursor: none;
        transition: background 0.3s ease;
        animation: fadeIn 0.5s ease;
        transform: scale(1);
        transition: transform 0.3s ease;

      }

      @keyframes fadeIn {
        from {
          opacity: 0;
          transform: scale(0.8);
        }
        to {
          opacity: 1;
          transform: scale(1);
        }
      }	

    
      .reviewButton:hover {
        background: linear-gradient(135deg, #BA9976, #bea68c);
        transform: translateY(-3px);
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
      }
    
      .reviewButton:active {
        transform: translateY(1px);  
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); 
      }
    .card {
      width: 95%;  
      margin: 15px auto;  
      padding: 15px;
      border: 1px solid #eaeaea;  
      background: linear-gradient(135deg, #ffffff, #f9f9f9);  
      border-radius: 15px;  
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  
      transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
  
    .card:hover {
      transform: scale(1.03); 
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
  
    .userInfo {
      justify-content: center;
      text-align: center;
    }
  
    .userImage {
      width: 50px; 
      height: 50px;
      margin-bottom: 10px; 
    }
  
    .userName {
      font-size: 0.9rem;
      font-weight: 600;
      color: #222;
    }
  
    .title {
      font-size: 1.1rem;
      margin-bottom: 10px;
      color: #444;
      text-align: center;
    }
  
    .review {
      font-size: 0.95rem;
      color: #555;
      line-height: 1.6;
      text-align: justify;  
    }
  
    .stars {
      justify-content: center;
      margin-bottom: 10px;
      font-size: 1.1rem; 
    }
    modalContent {
        padding: 15px;
      }
    
      .reviewForm input,
      .reviewForm textarea {
        font-size: 0.9rem;
      }
    
      .formButtons button {
        font-size: 0.85rem;
        padding: 8px 12px;
      }
  }
  
@media (max-width: 320px) {
    .header h2{
      font-size: 1.1rem;
    }
  
}