.skeletonContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
  justify-content: center;
  margin-top: 120px;
}

 .skeletonCard {
  width: calc(25% - 16px);  
  max-width: 240px;
  background-color: #f3f3f3;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

 .skeletonImage {
  width: 100%;
  height: 150px;
  background-color: #e0e0e0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.skeletonImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 200%;
  background: linear-gradient(90deg, #e0e0e0, #f5f5f5, #e0e0e0);
  animation: shimmer 1.5s infinite;
}

 .skeletonDetails {
  margin-top: 12px;
}

.skeletonTitle,
.skeletonPrice,
.skeletonBadge {
  height: 16px;
  background-color: #e0e0e0;
  margin-bottom: 8px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.skeletonTitle {
  width: 70%;
}

.skeletonPrice {
  width: 50%;
}

.skeletonBadge {
  width: 60%;
}

 @keyframes shimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

 @media (max-width: 768px) {
  .skeletonContainer{
      margin-top: 80px;
  }
  .skeletonCard {
    width: calc(33.3333% - 16px);  
  }

}

@media (max-width: 480px) {
  .skeletonCard {
    width: calc(50% - 16px); 
  }
}

@keyframes slideInLeft {
  0% {
      opacity: 0;
      transform: translateX(-100%);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
}

.icon {
  display: block;
  margin: auto;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2vw;
  padding: 1vw;
  cursor: pointer;
}

.filterSortSection {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  font-size: 14px;
  font-family: 'Futura', sans-serif;

}

.filter {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: #080808;
}

.filter:hover {
  color: #ba9976;
}
.sort, .sortd {
  display: flex;
  gap: 5px;
  white-space: nowrap;
  align-items: center;
  flex-wrap: wrap;
}

.sort select, .sortd select {
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  min-width: 360px;

}

.productCount {
  color: #666;
}

.allSariHeading {
  font-family: signika, sans-serif;
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
}


.maindiv {
  width: 15vw;
  height: auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  opacity: 0;   
  transform: translateX(-100%);   
}

.maindiv.visible {
  animation: slideInLeft 0.8s ease-in-out forwards;
}

.maindiv:hover {
  transform: scale(1.08);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 150, 120, 0.3);
}


.image {
  height: auto;
}

.image img {
  width: 100%;
  height: auto;
}
.outOfStockBadge {
  position: absolute;
  right:0px;
  background-color: red;
  color: white;
  padding: 3px 6px;
  border-radius: 5px;
  font-size: 12px;
}


.details p {
  font-weight: bold;
  font-size: 15px;
}

.details {
  text-align: center;
  padding: 5px 5px;
  font-size: 14px;
  font-family: 'Futura', sans-serif;
  letter-spacing: 1px;
  color: #333;
   border-radius: 0 0 10px 10px;
  transition: background-color 0.3s ease;
}
.preBooking {
  margin-top: 5px;
}

.preBooking span {
  color: #007a63;
  font-weight: bold;
}
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
}

.overlayContent {
  background-color: #fff;
  width: 80%;
  max-width: 400px;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.overlayContent h2 {
  margin-top: 20px;
  font-size: 1.5rem;
  text-align: center;
}

/* Filter Section */
.filterSection {
  margin: 20px 0;
}

.filterSection label {
  display: block;
  font-size: 1rem;
  margin-bottom: 8px;
}

.filterSection select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

/* Action Buttons */
.filterActions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.clearButton, .applyButton {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
}

.clearButton {
  background-color: #ccc;
  color: #333;
  border: none;
}

.applyButton {
  background-color: #ba9976;
  color: white;
  border: none;
}

.applyButton:hover {
  background-color: #8a6d53;
}



@media (max-width: 768px) {
  .allSariHeading{
      font-size: 34px;
       font-family: "Lucida Handwriting", cursive;
       font: bold;
  }
  .maindiv {
      width: 40vw;
       transition: none;
  }

  .maindiv:hover {
      transform: none;
      box-shadow: none;
  }
  .clearButton, .applyButton{
    cursor: none;
  }

  .filterSortSection {
    flex-direction: flex-start;
    align-items: row;
    padding: 10px;
}

.filter, .sort, .productCount{
    width: 100%;
    margin-bottom: 10px;
    cursor: none;
}
.sortd{
  display: none;
}
.productCount {
    text-align: center;
    padding-left: 80px;
}

.sort select, .sortd select {
  cursor: none;
}
.sort select{
    width: 100%;
    padding: 8px;
    font-size: 16px;
    min-width: 120px;

}

.closeButton{
  cursor: none;
}
  .outOfStockBadge{
      font-size: 9px;
      padding: 2px 4px;
  }
  .details {
      font-size: 12px;
  }

  .container {
      cursor: none;
  }
  .preBooking span{
      font-size: 12px;
      font: small-caption;
      font-weight: bold;
  }
  .overlayContent {
    width: 80%;
}
}

 