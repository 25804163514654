.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    position: fixed;
    right: 1rem;
    bottom: 2rem;
    z-index: 999;
    gap: 1rem;
}

.contactBtn {
    font-size: 2.4rem;
    width: 3.5rem;
    height: 3.5rem;
    color: #fff;
    background-color: #BA9976;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: fixed;
    bottom: 7rem; 
    right: 1rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 1;
    animation: fadeIn 0.5s, bounce 1.5s infinite alternate;
    transform: rotate(0deg);
}

.rotate {
    transform: rotate(90deg); 
    transition: transform 0.3s ease-in-out;
  }
.contactBtn .icon {
    width: 1.5rem;
    height: 1.5rem;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;

}
.contactBtn:hover, .topBtn:hover {
    background-color: #c4a98c;
    transform: scale(1.1); 
  }
  
.contactOptions {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 12rem;
    right: 1rem;
    z-index: 1000;
    gap: 0.75rem;
    animation: fadeIn 0.5s ease-in;
}

.contactOption {
    font-size: 2.4rem;
    width: 3.5rem;
    height: 3.5rem;
    color: #fff;
    /* background-color: #BA9976; */
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.contactOption .icon {
    width: 1.6rem;
    height: 1.6rem;
}
.opt1 { background-color: #04E68C; }
.opt1:hover { background-color: #36d998; }
.opt2 { background-color: #49E770; }
.opt2:hover { background-color: #3cd65f; }
.opt3 { background-color: #FD4861; }
.opt3:hover { background-color: #d63b4f; }


.topBtn {
    font-size: 2.4rem;
    width: 3.5rem;
    height: 3.5rem;
    color: #fff;
    background-color: #BA9976;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: fixed;
    bottom: 2rem;
    right: 1rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 1;
    animation: fadeIn 0.5s, bounce 1.5s infinite alternate;
}

.contactBtn:hover, .topBtn:hover {
    background-color: #c4a98c;
    transform: scale(1.1); 
  }

.icon {
    width: 2rem;
    height: 2rem;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
}

@media (max-width: 768px) {
    .topBtn {
        width: 2.5rem;
        height: 2.5rem;
        bottom: 1.5rem;
        right: 1rem;
        font-size: 1.5rem;
        cursor: none;
    }
    .contactBtn, .contactOption{
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1.5rem;
        bottom: 5rem;
        right: 1rem;
        cursor: none;
    }
    .contactBtn {
        display: flex;
    }
    .contactBtn .icon {
        width: 1rem;
        height: 1rem;
    }
    .contactOption .icon {
        width: 1.3rem;
        height: 1.3rem;
    }
    .icon {
        width: 1.5rem;
        height: 1.5rem;
    }
    .contactOptions {
        bottom: 9rem; 
        gap: 0.5rem;
    }
    
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes bounce {
    0% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}
