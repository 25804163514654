.container {
  text-align: center;
  padding: 20px 10px;
}

.heading {
  font-size: 2.5rem;
  font-weight: 400;
  color: #ba9976;
  margin-bottom: 1rem;
  animation: fadeIn 1s ease-in-out;
  font-family: Tiemann, serif;
}

.subheading {
  font-size: 1.2rem;
  color: #917454;
  margin-bottom: 2rem;
  animation: fadeIn 1.5s ease-in-out;
  font-family: Futura, sans-serif;
}

.categories {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  left: 10;
  padding: 10px 0;
  scroll-snap-type: x mandatory; 
  justify-content: center;
  scrollbar-width:none;
}
.category-card {
  flex: 0 0 auto;  
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 150px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.category-card:hover {
  transform: scale(1.1);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

 .category-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.category-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

 .category-title {
  font-size: 1rem;
  font-weight: 400;
  font-family: Futura, sans-serif;
  color: #ba9976;
}

 @media (max-width: 768px) {
  .container {
    padding: 20px 5px;
  }
  .heading{
    font-size: 1.9rem;
    font-family: Tiemann, serif;
    font-weight: 400;
  }
  .categories {
    justify-content: flex-start; 
    flex-wrap: nowrap; 
    overflow-x: auto;
    scrollbar-color: #ba9976;
    scroll-margin-block:inherit;
    margin-left: 20px;
  }

  .category-card {
    flex: 0 0 auto; 
    width: 120px;
    cursor: none;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 20px 4px;
  }
  .heading {
    font-size: 1.7rem;
  }

  .subheading {
    font-size: 1rem;
  }

  .category-card {
    width: 100px;  
  }

  .category-image {
    width: 100px;
    height: 100px;
  }

  .category-title {
    font-size: 0.9rem;
   }
}
